module.exports = {
  siteTitle: 'Shady Lane Boarding Kennel', // Site title.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://shadylaneboardingkennel.com/', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'At Shady Lane Boarding Kennel we provide compassionate, professional boarding for your dog or cat.', // Website description used for RSS feeds/meta description tag.
  googleTagManagerID: 'GTM-TQRWWGD', // GTM tracking ID.
  copyright: '© Shady Lane Boarding Kennel.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#3371bd', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}

import React, { Component } from 'react'
import { Link } from 'gatsby'
import logo from '../images/logo-only.png'
import config from '../../data/config'
import {
  NavbarBrand,
  NavbarMenu,
  NavbarEnd,
  NavbarItem,
  Field,
  Control,
  NavbarBurger,
} from 'bloomer'

export default class MainNav extends Component {
  constructor(props) {
    super(props)
    this.state = { isActive: false }
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar() {
    this.setState({ isActive: !this.state.isActive })
  }

  render() {
    return (
      <nav className="navbar is-primary" aria-label="main navigation">
        <NavbarBrand isColor="primary">
          <Link to="/" className="navbar-item">
            <img src={logo} alt="Logo" />
            <strong>{config.siteTitle}</strong>
          </Link>
          <NavbarBurger
            isActive={this.state.isActive}
            onClick={() => this.toggleNavbar()}
            data-target="navMenu"
          />
        </NavbarBrand>
        <NavbarMenu isActive={this.state.isActive}>
          <NavbarEnd>
            <Link className="navbar-item" to="/dog-boarding">
              Dog Boarding
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link" to="#">
                Other Services
              </Link>

              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/dog-grooming">
                  Dog Grooming
                </Link>
                <Link className="navbar-item" to="/cat-boarding">
                  Cat Boarding
                </Link>
                {/* <Link className="navbar-item" to="/dog-walking">
                  Dog Walking
                </Link> */}
              </div>
            </div>
            <Link className="navbar-item" to="/faq">
              F.A.Q.
            </Link>
            <Link className="navbar-item" to="/request-reservation">
              Request a Reservation
            </Link>
            <NavbarItem>
              <Field isGrouped>
                <Control>
                  <Link className="button is-outlined" to="/#contact-form">
                    Contact Us
                  </Link>
                </Control>
              </Field>
            </NavbarItem>
          </NavbarEnd>
        </NavbarMenu>
      </nav>
    )
  }
}

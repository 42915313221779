import React from 'react'
import Helmet from 'react-helmet'
import MainNav from './MainNav'
import ContactForm from './ContactForm'
import Iframe from 'react-iframe'
import Footer from './Footer'
import '../scss/styles.scss'
import config from '../../data/config'

export default ({ children, data, location }) => {
  let showContactForm = true
  if (
    typeof location !== 'undefined' &&
    location.pathname === '/request-reservation'
  ) {
    showContactForm = false
  }

  return (
    <div>
      <Helmet
        defaultTitle={config.siteTitle}
        titleTemplate={`%s | ${config.siteTitle}`}
      >
        <meta name="description" content={config.siteDescription} />
        <link
          href="https://fonts.googleapis.com/css?family=PT+Serif:400,700"
          rel="stylesheet"
        />
      </Helmet>
      <MainNav />
      {children}
      {showContactForm && <ContactForm />}
        <Iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11488.694193915653!2d-81.496209!3d43.955775!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9bcd87ce7d0e1ff9!2sShady+Lane+Boarding+Kennel!5e0!3m2!1sen!2sus!4v1558394013333!5m2!1sen!2sus"
          className="footerIframe" />
      <Footer />
    </div>
  )
}

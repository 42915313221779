import React from 'react'
import { Link } from 'gatsby'
import {
  Container,
  Title,
  Columns,
  Column,
  Section,
  Field,
  Label,
  Control,
  Input,
  TextArea,
  Button,
  Notification,
  Content,
} from 'bloomer'

const ContactForm = () => {
  return (
    <Section
      className="contact-form has-background-white-ter"
      id="contact-form"
    >
      <Title tag="h2" hasTextAlign="centered">
        Contact Us
      </Title>
      <Container className="container--narrow">
        <Columns isMultiline>
          <Column isSize="1/2">
            <Content>
              <h3>Phone Number</h3>
              <p>
                <a href="tel:5195282938">(519) 528-2938</a>
              </p>
              <h3>Our Hours</h3>
              <table class="hours-table" summary="Kennel Hours">
                <tbody>
                  <tr>
                    <td class="col1">Monday - Sunday:</td>
                    <td class="col2">
                      8am - 10am and 
                      <br />
                      5pm - 7pm
                    </td>
                  </tr>
                </tbody>
              </table>
            </Content>
          </Column>
          <Column isSize="1/2">
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              {/* You still need to add the hidden input with the form name to your JSX form */}
              <input type="hidden" name="form-name" value="contact" />
              <Notification className="has-background-white-bis">
                If you would like to book a reservation with us please use the{' '}
                <Link
                  title="Request a Reservation"
                  to="/request-reservation/"
                >
                  request a reservation form
                </Link>
                .
              </Notification>

              <Field>
                <Label>Name</Label>
                <Control>
                  <Input type="text" name="name" />
                </Control>
              </Field>

              <Field>
                <Label>Email</Label>
                <Control>
                  <Input type="email" name="email" />
                </Control>
              </Field>

              <Field>
                <Label>Message</Label>
                <Control>
                  <TextArea name="message" />
                </Control>
              </Field>

              <Field isGrouped>
                <Control>
                  <Button isColor="primary" type="submit">Submit</Button>
                </Control>
              </Field>
            </form>
          </Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default ContactForm
